import React from 'react';
import { Link } from 'react-router-dom';
import copyTextToClipboard from 'shared/foreground/utils/copyTextToClipboard';

// eslint-disable-next-line @shopify/images-no-direct-imports
import personImageUrl from '../person.svg';
import fixedEncodeURIComponent from '../utils/fixedEncodeURIComponent';
import getNumericCssPropertyValue from '../utils/getNumericCssPropertyValue';
import CopyButton from './CopyButton';
import ChevronRightIcon from './icons/ChevronRightIcon';
import styles from './PersonCard.module.css';
import Tooltip from './Tooltip';

// Takes an element and generates a table of contents. It will update the content if needed
export default function PersonCard({
  additionalText,
  additionalTextTooltip = '',
  className,
  name = 'Jane Doe',
  url,
}: {
  additionalText?: string;
  additionalTextTooltip?: string;
  className?: string;
  name?: string;
  url?: string;
}): JSX.Element {
  const classes = [styles.root, className].filter(Boolean);

  const getChevron = () => {
    if (!url) {
      return;
    }
    return <ChevronRightIcon className={styles.chevron} text="" />;
  };

  const getAdditionalText = () => {
    if (additionalTextTooltip) {
      return (
        <Tooltip content={additionalTextTooltip} placement="top">
          <div className={styles.additionalText}>{additionalText}</div>
        </Tooltip>
      );
    }

    return <div className={styles.additionalText}>{additionalText}</div>;
  };

  const copyName = (name: string): void => {
    copyTextToClipboard(name, {
      successToastMessage: 'Copied name to clipboard',
    });
  };

  const content = (
    <Tooltip content="View everything by this author" placement="bottom">
      <Link to={`/filter/author:"${fixedEncodeURIComponent(name)}"`} style={{ display: 'inline-flex' }}>
        <span className={styles.imageWrapper}>
          <img
            alt={name}
            src={personImageUrl}
            className={styles.image}
            height={getNumericCssPropertyValue('--person-card-image-size')}
            width={getNumericCssPropertyValue('--person-card-image-size')}
          />
        </span>
        <span className={styles.textWrapper}>
          {name && (
            <div className={styles.nameWrapper}>
              <span className={styles.name}>{name}</span>
              <CopyButton
                className={styles.copyButton}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  copyName(name);
                }}
              />
            </div>
          )}
          {getAdditionalText()}
        </span>
        {getChevron()}
      </Link>
    </Tooltip>
  );

  if (url) {
    return (
      <Link className={classes.join(' ')} to={url}>
        {content}
      </Link>
    );
  }

  return <span className={classes.join(' ')}>{content}</span>;
}
