import React, { useEffect, useMemo, useState } from 'react';
import eventEmitter from 'shared/foreground/eventEmitter';
import { ShortcutId } from 'shared/types/keyboardShortcuts';
import { notEmpty } from 'shared/typeValidators';

import { useShortcutsMap } from '../../utils/shortcuts';
import { getSeparatorOption } from './docOptions';
import styles from './DocumentNoteDropdown.module.css';
import { Dropdown, DropdownOption, DropdownOptionType } from './Dropdown';

export default function DocumentNoteDropdown({
  buttonClassName,
  copyNote,
  deleteNote,
  note,
  onEscapeKeyDown,
}: {
  buttonClassName?: string;
  copyNote: () => void;
  deleteNote: () => void;
  note?: string;
  onEscapeKeyDown?: () => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    eventEmitter.on('set-doc-note-is-open', setIsOpen);
    return () => {
      eventEmitter.off('set-doc-note-is-open', setIsOpen);
    };
  }, []);

  const shortcutsMap = useShortcutsMap();

  const options = useMemo((): DropdownOption[] => {
    let shareOption: DropdownOption | undefined;

    if ('share' in navigator) {
      shareOption = {
        isDisabled: !note,
        name: 'Share',
        onSelect: () => {
          if (!note) {
            return;
          }
          navigator.share({
            text: note,
          });
        },
        type: DropdownOptionType.Item,
      };
    }

    return [
      {
        isDisabled: !note,
        name: 'Copy',
        onSelect: copyNote,
        shortcut: shortcutsMap[ShortcutId.CopyDocumentNote],
        type: DropdownOptionType.Item,
      },
      shareOption,
      getSeparatorOption(),
      {
        className: styles.deleteOption,
        isDisabled: !note,
        name: 'Delete',
        onSelect: deleteNote,
        shortcut: shortcutsMap[ShortcutId.DeleteDocumentNote],
        type: DropdownOptionType.Item,
      },
    ].filter(notEmpty);
  }, [copyNote, deleteNote, note, shortcutsMap]);

  return (
    <>
      <Dropdown
        contentAlignment="end"
        contentClassName={styles.content}
        defaultTriggerAttributes={{
          tabIndex: -1,
        }}
        isOpen={isOpen}
        onEscapeKeyDown={onEscapeKeyDown}
        options={options}
        setIsOpen={setIsOpen}
        triggerClassName={[styles.button, buttonClassName].filter(Boolean).join(' ')}
        triggerShortcut={shortcutsMap[ShortcutId.OpenDocumentNoteActionsDropdown]}
        triggerTooltipText="Document note actions"
      />
    </>
  );
}
